import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import JaegerLeCoultureCategoryBtns from '../../components/preowned/JaegerLeCoultureCategoryBtns'

// markup
const JaegerLeCoultureMain = () => {
  const data = useStaticQuery(
    graphql`
      query queryJaegerLeCoultureMain {
        products: allStrapiProduct(
          filter: { brand: { eq: "Jaeger LeCoulture" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Jaeger LeCoultre Reverso Watches for Sale'}
      canonical={'/fine-watches/other-watches-brands/jaeger-lecoultre/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Jaeger LeCoultre Reverso with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
              <StaticImage
                src="../../images/preowned/preowned-certifeid-used-jaeger-lecoulture-reverso-watches-banner-1920x1080.jpg"
                alt="Pre-Owned Certified Used Jaeger LeCoulture Watches Header"
                aria-label="Used Jaeger LeCoulture Watch Site Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">USED JAEGER LECOULTRE REVERSO WATCHES</h1>
            <h2>
              {' '}
              Buy, Sell, Trade, Consign, Repair, and Restore{' '}
              <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                {' '}
                &nbsp;Jaeger LeCoultre Reverso Watches
              </a>{' '}
            </h2>
            <h3>
              Buy, Sell, Trade, and Repair Used and Pre=Owned{' '}
              <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                Jaeger LeCoultre Reverso
              </a>{' '}
              Watches with Gray &amp; Sons.
            </h3>
            <p>
              Gray and Sons is the #1 real watch and jewelry store located in Surfside, Miami,
              Florida. Across from Bal Harbour Shops. Gray and Sons is known for our real watch and
              jewelry experts on-site, open six days a week, and that we're real, not a virtual
              store. Our customers say we are the #1 Miami used watch buyer and seller proudly
              assisting for the past 42 years. We're sellers and buyers of pre-owned, used and new
              Jaeger LeCoulture watches in Miami, Miami Beach, Coral Gables, Coconut Grove, South
              Beach, Bal Harbour, Surfside, Sunny Isles , Aventura, Fort Lauderdale and many more
              areas.
              <br />
              <br />
              Gray and Sons Jewelers sells certified authentic pre-owned JAEGER LECOULTRE REVERSO
              Watches. Gray and Sons and their buying division &ensp;
              <a href="https://sellusyourjewelry.com">sellusyourjewelry.com</a> Will also buy your
              used{' '}
              <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                Jaeger LeCoultre Reverso
              </a>{' '}
              Watch for cash. We are the #1 independent watch dealer. Our expert, Swiss trained,
              watch repairmen will service your watch and replace your{' '}
              <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                Jaeger LeCoultre Reverso
              </a>{' '}
              Watch battery. We have a huge in-stock inventory at amazing prices. Talk to a real
              representative now.
              <br />
              <br />
              The JLC Reverso has been a crowd favorite for decades. The story of how this watch
              model came to be is quite interesting. Back in 1930 when watches were primarily
              fabricated with round cases and movements, a friend of Jacques-David LeCoultre named
              Cesar De Trey was approached by a British Officer with the crystal of his watch
              smashed in. It turns out, the British officer was participating in a Polo match when
              an unfortunate turn of events caused the crystal of his watch to completely shatter.
              This sparked the idea of creating a watch with the ability to flip over thus
              protecting the watch face. By March of 1931, JLC had submitted the application to the
              patent office of Paris to create a timepiece “capable of sliding into its housing and
              turning over completely”. The name “Reverso” meaning “I turn round” in Latin was filed
              for by De Trey and LeCoultre in November of that same year, allowing them to begin
              advertising and sales for the timepiece less than nine months after sending the patent
              application. Sales for this watch began skyrocketing almost immediately. Fanatics of
              the Art Deco aesthetics were quickly enamored with the Reverso and soon thereafter JLC
              released the ladies' version which featured the same wild concept in smaller case
              size. These timepieces were offered to the public in white, rose and yellow gold along
              with an option for “StayBrite'' steel which consumers loved for its
              scratch-resistance. As popularity rose for the Reverso, so did consumers’ options; JLC
              began to incorporate various colored dials and case engravings. Soon after this
              expansion in the Reverso Collection, the watch world experienced a halt in sales and
              production due to World War II and the Quartz craze. It was not until 1975 that the
              Jaeger LeCoultre Reverso made a comeback and a collaboration with Daniel Wild led to
              the rebirth of the New style of Reverso watches in 1985. For its 60th Anniversary in
              1991, the Reverso was given the iconic design we know and love today. Shortly
              thereafter, the Duoface Reverso, which displayed a different time on the opposing
              side, was released. To date, this new era of Reverso's comes water-resistant and
              carries 55 components instead of the original 23. Around this time the ladies’ version
              of the Reverso was renamed “Duetto” and the color burgundy, chocolate, bright red, and
              blue lacquer dials made a grand return with leather straps to match. Many consider the
              Reverso collection to be the first sporty yet stylish watch to be crafted for watch
              collectors. From the 90s to the 00s, JLC got experimental with their Reverso
              collection, establishing the top three crowds' favorite Reverso models; the{' '}
              <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                Jaeger LeCoultre Reverso
              </a>{' '}
              Royalty and prestige are the main features associated with{' '}
              <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                Jaeger LeCoultre Reverso Watches.
              </a>{' '}
              The luxury jewelry and watch brand, which for many years now has 100% brand
              recognition. The technical mastery, innovation, and timeless design grant{' '}
              <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                Jaeger LeCoultre Reverso
              </a>{' '}
              Watches a special place within the luxury jewelry and wristwatch market, which also
              means that the prices increase over time. For that reason, many people often decide to
              own one of the prestigious JAEGER LECOULTRE REVERSO Watches not only as a luxurious
              accessory but also as an investment.
              <br />
              <br />
              Since its release, the JLC Reverso has been a huge success. These elegant timepieces
              draw the attention of any watch enthusiast that comes near them. The Jaeger LeCoultre
              Reverso collection is one of Jaeger LeCoustre’s universal pieces appropriate for
              almost any setting. Under the umbrella of Jaeger LeCoultre Reverso watches fall the
              following references:
              <li>
                {' '}
                &ensp;
                <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                  Popular Pre-Owned Used Jaeger LeCoultre Reverso Watch References and Models:
                </a>{' '}
              </li>
              <li>
                {' '}
                &ensp;
                <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                  Pre-Owned Jaeger LeCoultre Reverso Classic references: Q2438522, Q3858520,
                  Q2518540{' '}
                </a>{' '}
              </li>
              <li>
                {' '}
                &ensp;
                <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                  Pre-Owned Jaeger LeCoultre Reverso Duetto references: Q3348420, 266.1.44,
                  296.1.74,3352420
                </a>{' '}
              </li>
              <li>
                {' '}
                &ensp;
                <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                  Pre-Owned Jaeger LeCoultre Reverso One Quartz references: Q3288120,
                  Q3288420,Q3288560
                </a>{' '}
              </li>
              <li>
                {' '}
                &ensp;
                <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                  Pre-Owned Jaeger LeCoultre Reverso Duoface references: 3848420, Q3988482, 270.1.54{' '}
                </a>{' '}
              </li>
              <br />
              <br />
              Since 1980, <a href="/">Gray and Sons</a> Jewelers has been catering to clients all
              around the country with all their luxury needs. All of the services we provide are
              done in our very own store, here, in the heart of Miami, Florida. We are the top watch
              store in all of South Florida specializing in buying pre-owned Jaeger LeCoultre
              watches,Selling used Jaeger LeCoultre watches, repairing second-hand Jaeger LeCoultre
              watches, and trading genuine Jaeger LeCoultre timepieces. Gray and Sons Jewelers is
              the #1 all-stop shop for all Jaeger LeCoultre watch needs. Our store is located in
              South Florida in the city of Surfside across the street from the famous Bal Harbour
              Shops. Aside from buying Jaeger LeCoultre Watches and selling genuine pre-owned Jaeger
              LeCoultre watches, <a href="/">Gray and Sons</a>
              is also a certified repair center for all Swiss-made wristwatches. We have six
              swiss-trained watchmakers in-house with over 150 years of combined experience to
              repair Jaeger LeCoultre watches, including the reparation of a Jaeger LeCoultre
              Reverso. We take in repairs for swiss-made watches daily!
              <br />
              <br />
              For more details, visit our showroom in Surfside, Miami, or check our website{' '}
              <a href="/fine-watches/JaegerLeCoulture/">www.grayandsons.com</a>. Our company is
              proud to have qualified and trusted in-house watchmakers and jewelers who stand ready
              to assist our customers. Make the best choice and buy your dream certified pre-owned
              Jaeger LeCoulture watch for the best price. The finest selection of used Jaeger
              LeCoulture watches - only at{' '}
              <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                Gray &amp; Sons.
              </a>
              . Those looking to “get cash for my used Jaeger LeCoultre watch” or “sell my pre-owned
              Jaeger LeCoultre watch today” should visit <a href="/"> www.grayandsons.com</a> or{' '}
              <a href="https://sellusyourjewelry.com/what-are-you-looking-to-sell-2/">
                {' '}
                www.sellusyourjewelry.com
              </a>{' '}
              to request a free watch quote to sell or trade-in for one of ours! We have a
              brick-and-mortar store located in the heart of Miami, Fl near cities like Sunny Isles,
              Haulover, Aventura, Hollywood, Fort Lauderdale, Miami Beach, Coconut Grove, and Coral
              Gables, Key Biscayne, and many more. On our website, we have LIVE representatives
              Viktoria and Rich who are specialists in buying and selling Jaeger LeCoultre Reverso
              watches. Our LIVE CHAT feature allows clients all around the nation to make all
              inquiries about Jaeger LeCoultre watches and possibly work out a deal in real-time.
              Gray and Sons Jewelers is the best and most reliable place to buy used pre-owned
              Jaeger LeCoultre watches. Our swiss-trained watchmakers assure every Jaeger LeCoultre
              watches in our inventory is certified authentic and serviced ready for wear with a
              1-year warranty covering the movement.
              <br />
              <br />
              We are so excited to hear from you and have you sign up for our free monthly catalog.
              Visit the store, call or visit our website at <a href="/">www.grayandsons.com</a>.
              Lot’s of people are buying used and pre-owned Jaeger LeCoulture Watches, and many
              people are looking for a place to sell their used Jaeger LeCoulture Watches. Gray and
              Sons will do both. If you’re one of the people saying, “I want to sell my Jaeger
              LeCoulture wrist watch for cash” visit <a href="/">www.grayandsons.com</a> and{' '}
              <a href="https://sellusyourjewelry.com">sellusyourjewelry.com</a> and we will buy your
              pre-owned Jaeger LeCoultre Reverso Watch for cash. Would you like to{' '}
              <a href="/chat-with-rich">MAKE AN OFFER</a> on watches and jewelry? Vika and Rich are
              live expert decision makers and ready to work with you now.
              <br />
              <br />
            </p>
            <div className="w-full flex mx-auto justify-center pb-24 pt-24">
              <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                <button className="pt-48">Shop Jaeger LeCoultre Reverso Watches</button>{' '}
              </a>
            </div>

            <br />
            <iframe
              className="content-center"
              src="https://www.google.com/maps/embed?pb=!1m19!1m8!1m3!1d7178.937737903439!2d-80.1235056!3d25.8869528!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x88d9b2992b408533%3A0x8d702d6011cda310!2sGray%20and%20Sons%20Jewelers%7C%20Luxury%20Estate%20Watches%20%26%20Jewelry%209595%20Harding%20Ave%20Surfside%2C%20FL%2033154!3m2!1d25.8869528!2d-80.1235056!5e0!3m2!1sen!2sus!4v1655907861510!5m2!1sen!2sus"
              width="1250"
              height="720"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10"> IN-STOCK Jaeger LeCoulture Watches</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <section>
        <JaegerLeCoultureCategoryBtns />
      </section>

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/Ih7LroA6z4A'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default JaegerLeCoultureMain
